import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import defaultConnect from '../../../components/redux/defaultConnect';
import makeRequest from '../../../lib/apiService';

moment.locale('es');

class DetallesVehiculo extends React.Component {
  state = {
    vehicle: {},
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { setLoading, pushError, match: { params: { id } } } = this.props;
    try {
      setLoading(true);
      const vehicle = await makeRequest(
        `vehicles/${id}`,
        { method: 'GET' },
      );
      this.setState({ vehicle }, () => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  render() {
    const { vehicle, driver, user } = this.state;

    let disabled = null;
    switch (vehicle.disabled) {
      case true:
        disabled = <span className="badge badge-warning">Deshabilitado</span>;
        break;
      case false:
        disabled = <span className="badge badge-success">Activo</span>;
        break;
      default:
        disabled = <span className="badge badge-dark">N/A</span>;
        break;
    }

    let power = null;
    switch (vehicle.power) {
      case true:
        power = <span className="badge badge-success">Encendido</span>;
        break;
      case false:
        power = <span className="badge badge-danger">Apagado</span>;
        break;
      default:
        power = <span className="badge badge-dark">N/A</span>;
        break;
    }

    let linked = null;
    switch (vehicle.linked) {
      case true:
        linked = <span className="badge badge-success">Vinculado</span>;
        break;
      case false:
        linked = <span className="badge badge-warning">Sin vincular</span>;
        break;
      default:
        linked = <span className="badge badge-dark">N/A</span>;
        break;
    }

    return (
      <div className="detalles-concesionario detalles">
        <div className="row">

          <div className="col-12 header">
            <h3>Vehículo #{vehicle.id_vehicle}</h3>
            {disabled}
            {linked}
            {power}
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Clave de vehículo</p>
            <p className="value">{vehicle.key_vehicle}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">IMEI</p>
            <p className="value">{vehicle.imei}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Número de teléfono</p>
            <p className="value">{vehicle.phone_number}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Placas</p>
            <p className="value">{vehicle.plate_number}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Descripción</p>
            <p className="value">{vehicle.description}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Notas</p>
            <p className="value">{vehicle.notes}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Fecha de registro</p>
            <p className="value">{moment(vehicle.createdAt).format('DD-MMMM-YYYY hh:mm a')}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Concesionario</p>
            {
              vehicle.user
                ? (
                  <Link className="badge badge-primary" to={`/panel/concesionarios/concesionario/detalles/${vehicle.user.id_user}`}>
                    #{vehicle.user.id_user} {vehicle.user.name} {vehicle.user.last_name}
                  </Link>
                )
                : <p className="value">Sin concesionario asignado</p>
            }
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Chofer</p>
            {
              vehicle.driver
                ? (
                  <Link className="badge badge-primary" to={`/panel/choferes/chofer/detalles/${vehicle.driver.id_driver}`}>
                    #{vehicle.driver.id_driver} {vehicle.driver.name} {vehicle.driver.last_name}
                  </Link>
                )
                : <p className="value">Sin chofer asignado</p>
            }
          </div>

        </div>
      </div>
    );
  }
}

export default defaultConnect(DetallesVehiculo);
