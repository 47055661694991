import React from 'react';
import { WafoForm, WafoFormInput } from 'wafo-forms';
import { Redirect, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import makeRequest from '../../lib/apiService';
import { storeToken, getToken } from '../../lib/authService';
import AlertasList from '../../components/alerta/alerta'; // pop-up msgs
import defaultConnect from '../../components/redux/defaultConnect';
import './styles.css';

class Login extends React.Component {

  state = {
    toPanel: false
  };

  componentDidMount() {
    const token = getToken();
    if (token) {
      this.setState({ toPanel: true });
    }
  }

  handleFormSubmit = async (form) => {
    const { pushError } = this.props;

    if (!form.valid) { return; }
    try {
      const resp = await makeRequest(
        'auth/login',
        { method: 'POST' },
        {
          email: form.email.value,
          password: form.password.value
        }
      );
      storeToken(resp.token);
      this.setState({ toPanel: true });
    } catch (error) {
      pushError(error.message);
    }
  }

  render() {
    const { toPanel } = this.state;

    if (toPanel) {
      return <Redirect to="/panel" />;
    }

    return (
      <div className="login">
        <div className="container-fluid">
          <AlertasList />
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-sm-12 col-md-3">

              <div className="card mirai-shadow">
                <div className="card-body">

                  <h1>Celso GPS</h1>

                  <WafoForm buttonText="Iniciar sesión" onSubmit={this.handleFormSubmit}>

                    <WafoFormInput
                      type="email"
                      name="email"
                      label="Correo electrónico"
                      placeholder="Correo electrónico"
                      customClass="col-12"
                      validations={{
                        required: true,
                        email: true,
                      }}
                    />

                    <WafoFormInput
                      type="password"
                      name="password"
                      label="Contraseña"
                      placeholder="Contraseña"
                      customClass="col-12"
                      validations={{
                        required: true,
                      }}
                    />

                  </WafoForm>

                  <Link to="/login/forgot" className="btn btn-link">
                    <FontAwesomeIcon icon={faKey} style={{ marginRight: '.25rem' }} />
                    Recuperar contraseña
                  </Link>

                  <p className="motd">
                    <FontAwesomeIcon icon={faCopyright} style={{ marginRight: '.25rem' }} />
                    Celso GPS 2019
                  </p>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default defaultConnect(Login);
