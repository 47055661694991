import Leaflet from 'leaflet';

export const markerOnline = new Leaflet.icon({
  iconUrl: require('../../../../assets/images/markers/marker-online.png'),
  iconSize: [31, 45],
  iconAnchor: [15, 45],
  popupAnchor: [0, -40]
});

export const markerOffline = new Leaflet.icon({
  iconUrl: require('../../../../assets/images/markers/marker-offline.png'),
  iconSize: [31, 45],
  iconAnchor: [15, 45],
  popupAnchor: [0, -40]
});

export const markerOff = new Leaflet.icon({
  iconUrl: require('../../../../assets/images/markers/marker-off.png'),
  iconSize: [31, 45],
  iconAnchor: [15, 45],
  popupAnchor: [0, -40]
});

export const markerNogps = new Leaflet.icon({
  iconUrl: require('../../../../assets/images/markers/marker-nogps.png'),
  iconSize: [31, 45],
  iconAnchor: [15, 45],
  popupAnchor: [0, -40]
});
