import React from 'react';
import moment from 'moment-timezone';
import defaultConnect from '../../../components/redux/defaultConnect';
import LocationsMapa from './mapa/mapa';
import TablaHistorial from './tablaHistorial';
import makeRequest from '../../../lib/apiService';
import './styles.css';
import Distancias from './distancias/distancias';

const initialPosition = {
  position: [29.0729673, -110.9559192],
  zoom: 11,
};

const defaultFilters = {
  vehicle: '',
  date: moment().tz('America/Hermosillo').format('YYYY-MM-DD'),
  fromDate: moment().tz('America/Hermosillo').format('YYYY-MM-DDT00:00'),
  toDate: moment().tz('America/Hermosillo').format('YYYY-MM-DDT23:59'),
};

class Historial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: {
        ...initialPosition,
        key: Math.random(),
        customMarker: null,
      },
      filters: defaultFilters,
      vehiclesFilter: [],
      locations: [],
      table: {
        rows: [],
        total: 0,
        activePage: 1,
        size: 10,
      },
      distance: null
    };

    this.vehicleAutocomplete = React.createRef();
  }

  componentDidMount() {
    this.getVehiclesFilter();
  }

  /** ****************************** */
  /** Map stuff */
  /** ****************************** */

  // Setting map ref to acces leaflet methods.
  setMapRef = (event) => {
    if (event) {
      this.map = event.leafletElement;
      return this.map;
    }
  }

  // Changes map key to force re-render
  forceMapUpdate = () => this.setState((prevState) => ({
    map: {
      ...prevState.map,
      key: Math.random(),
    },
  }));

  // Resets maps position to default
  resetMap = () => {
    this.map.flyTo(initialPosition.position, initialPosition.zoom);
  }

  mapLocate = (id_location) => {
    const { locations } = this.state;
    const { pushAlerta } = this.props;

    document.getElementsByClassName('mapa-locations')[0].scrollIntoView({ behavior: 'smooth' });

    const location = locations.find(x => x.id === id_location);
    if (!location) {
      pushAlerta({
        type: 'warning',
        title: 'Ubicación no encontrada',
        message: 'No se pudo encontrar esa ubicación en el mapa. Verifique los filtros.',
        timer: 5000,
      });
      this.map.flyTo(initialPosition.position, initialPosition.zoom);
      return;
    }
    this.map.flyTo([location.latitude, location.longitude], 18);
    this.setState(prevState => ({
      map: {
        ...prevState.map,
        customMarker: {
          latitude: location.latitude,
          longitude: location.longitude,
          title: moment(location.createdAt).format('DD/MM/YYYY hh:mm:ss a'),
        },
      },
    }));
  }

  /** ****************************** */
  /** Data and filters */
  /** ****************************** */

  getVehiclesFilter = async () => {
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      const vehicles = await makeRequest(
        `vehicles/?limit=${999999}`,
        { method: 'GET' },
      );
      this.setState({
        vehiclesFilter: vehicles.rows,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  };

  // get vehicles from /monitor
  getData = async () => {
    const { setLoading, pushError } = this.props;
    const { filters: { vehicle, fromDate, toDate } } = this.state;

    if (!vehicle) { return; }

    /* const fromDate = moment(`${date} ${filterFromDate.military}`, 'YYYY-MM-DD HH:mm:ss')
      .tz('America/Hermosillo').toISOString();
    const toDate = moment(`${date} ${filterToDate.military}`, 'YYYY-MM-DD HH:mm:ss')
      .tz('America/Hermosillo').toISOString(); */

    try {
      setLoading(true);
      const resp = await makeRequest(
        `history/distance/?limit=999999`
        + `&id_vehicle=${vehicle.id_vehicle}`
        + `&fromDate=${moment(fromDate).toISOString()}`
        + `&toDate=${moment(toDate).toISOString()}`,
        { method: 'GET' },
      );

      const { history, distance } = resp;

      this.setState((prevState) => ({
        locations: history.rows,
        table: {
          ...prevState.table,
          rows: history.rows.slice(0, prevState.table.size),
          total: history.count,
          activePage: 1,
        },
        distance,
      }), () => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  clearFilters = () => {
    this.vehicleAutocomplete.current.clearForm();
    this.setState({
      filters: defaultFilters,
      locations: [],
      table: {
        rows: [],
        total: 0,
        activePage: 1,
        size: 10,
      },
      distance: null,
    });
  }

  handleFilterChange = (event) => {
    const { target: { name, value } } = event;
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [name]: value,
      }
    }));
  }

  /** ****************************** */
  /** Table */
  /** ****************************** */

  paginationHandler = (event, value) => {
    const { table, table: { activePage } } = this.state;

    switch (event) {
      case 'page': {
        let pagina = value;
        if (pagina === 'prev') {
          pagina = (activePage - 1);
        } else if (pagina === 'next') {
          pagina = (activePage + 1);
        }
        this.getTableData(pagina);
        break;
      }
      case 'size':
        this.setState({ table: { ...table, size: parseInt(value, 10) } }, () => {
          this.getTableData(activePage);
        });
        break;
      default: break;
    }
  }

  getTableData = (page = 1) => {
    const { locations, table: { size } } = this.state;
    const rows = locations.slice((size * (page - 1)), size * page);
    this.setState(prevState => ({
      table: {
        ...prevState.table,
        rows,
        activePage: page,
      },
    }));
  }

  render() {
    const { locations, map, vehiclesFilter, filters, table, distance } = this.state;

    return (
      <div className="container historial">
        <div className="row">
          <div className="col">
            <h2>Historial de ubicaciones</h2>
          </div>
        </div>

        <LocationsMapa
          map={map}
          mapRef={this.setMapRef}
          forceMapUpdate={this.forceMapUpdate}
          resetMap={this.resetMap}
          locations={locations}
          getData={this.getData}
          filters={filters}
          clearFilters={this.clearFilters}
          vehiclesFilter={vehiclesFilter}
          handleFilterChange={this.handleFilterChange}
          vehicleAutocompleteRef={this.vehicleAutocomplete}
          customMarker={map.customMarker}
        />


        {distance && (
          <React.Fragment>
            <div style={{ padding: '1.5rem' }} />
            <Distancias distance={distance} />
          </React.Fragment>
        )}

        <div style={{ padding: '1.5rem' }} />

        <TablaHistorial
          table={table}
          paginationHandler={this.paginationHandler}
          mapLocate={this.mapLocate}
        />

      </div>
    );
  }
}

export default defaultConnect(Historial);
