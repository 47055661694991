import React from 'react';
import styles from './distancias.module.css';

const Distancias = ({ distance }) => {
  return (
    <div id="distancias">
      <div className={`card mirai-shadow ${styles['card']}`}>
        <div className="card-body">
          <h5><strong>Distancia recorrida</strong></h5>
          <ul className={styles['list']}>
            {Object.keys(distance).map(key => (
              <li key={key}>
                <span className={styles['title']}>Fecha {key}: </span>
                <span>{(distance[key] / 1000).toFixed(2)} Km</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Distancias;