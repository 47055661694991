import React from 'react';
import { Datatable } from 'wafo-table';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';

const TablaHistorial = ({ table, paginationHandler, mapLocate }) => {
  const columns = ['Modo', 'Velocidad', 'Dirección', 'Fecha', 'Opciones'];

  const rows = table.rows.map(row => ({
    mode: row.mode,
    speed: row.speed,
    direction: row.direction,
    date: { timestamp: row.createdAt, timestampGPS: row.timestamp_gps },
    options: { id: row.id, latitude: row.latitude, longitude: row.longitude },
  }));

  /* eslint-disable react/display-name */
  const configTable = {
    columnDef: {
      speed: val => (<span>{val.toFixed(2)} km/h</span>),
      date: val => {
        const gpsTimeArray = val.timestampGPS.match(/.{1,2}/g).map(x => parseInt(x, 10));
        return (
          <p style={{ marginBottom: '0' }}>
            <span title="GPS time">{
              moment(
                new Date(Date.UTC(
                  2000 + gpsTimeArray[0],
                  gpsTimeArray[1] > 0 ? gpsTimeArray[1] - 1 : 0,
                  gpsTimeArray[2],
                  gpsTimeArray[3] + 7, // assuming time is in -7
                  gpsTimeArray[4],
                  gpsTimeArray[5] || '00',
                ))
              ).format('DD-MMM-YYYY HH:mm:ss')
            }</span>
            <br />
            <span title="Server time" style={{ fontSize: '0.8em' }}>
              {moment(val.timestamp).format('DD-MMM-YYYY HH:mm:ss')}
            </span>
          </p>
        );
      },
      options: val => (
        <React.Fragment>
          <a
            className="btn btn-sm btn-light"
            title="Ir a Google Maps"
            href={`https://www.google.com/maps/search/?api=1&query=${val.latitude},${val.longitude}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faMapMarkedAlt} />
          </a>
          <button type="button" className="btn btn-sm btn-info" title="Ver en mapa" onClick={() => { mapLocate(val.id); }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '.25rem' }} />
            Ver en mapa
          </button>
        </React.Fragment>
      ),
    },
    columnStyle: {
      date: {
        fontSize: '0.9em',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
      },
      options: {
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
      }
    },
  };
  /* eslint-enable react/display-name */

  return (
    <div className="tabla-historial datatable-wrapper">
      <div className="card mirai-shadow">
        <h5 className="card-header">Historial de ubicaciones</h5>
        <div className="card-body">

          <Datatable
            tableProps={{
              columns,
              data: rows,
              configTable,
            }}
            total={table.total}
            activePage={table.activePage}
            size={table.size}
            paginacionEvent={paginationHandler}
            updateTable={() => { /* */ }}
            datatableClass="table-sm"
          />

        </div>
      </div>
    </div>
  );
};

export default TablaHistorial;
