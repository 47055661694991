import React from 'react';
import { WafoForm, WafoFormInput, WafoFormAutocomplete } from 'wafo-forms';
import { Redirect } from 'react-router-dom';
import meConnect from '../../../components/redux/meConnect';
import makeRequest from '../../../lib/apiService';

class FormaVehicle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicle: {
        id_vehicle: 0,
      },
      redirect: false,
      concesionarios: [],
      choferes: [],
    };

    this.autocompleteConcesionario = React.createRef();
    this.autocompleteChofer = React.createRef();
  }

  async componentDidMount() {
    const { match: { params: { id } }, me } = this.props;
    if (Object.keys(me).length !== 0) {
      await this.checkMe();
      await this.getChoferes();
      if (id) {
        this.getVehicle();
      }
    }
  }

  // TODO: Por si entran directo a esta ruta y aun no esta cargado el "me"
  async componentDidUpdate(prevProps) {
    const { match: { params: { id } }, me } = this.props;
    if (prevProps.me !== me) {
      await this.checkMe();
      await this.getChoferes();
      if (id) {
        this.getVehicle();
      }
    }
  }

  // TODO: para ver el tipo de usuario.
  checkMe = async () => {
    const { match: { params: { id } }, me } = this.props;
    if (me.type === 1) {
      await this.getConcesionarios();
    } else {
      this.setState(prevState => ({
        vehicle: {
          ...prevState.vehicle,
          id_vehicle: Math.random(),
          id_user: me,
        },
        concesionarios: [me],
      }));
    }
  }

  getConcesionarios = async () => {
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      let concesionarios = await makeRequest(
        `users/?limit=99999`,
        { method: 'GET' },
      );
      this.setState({ concesionarios: concesionarios.rows }, () => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  getChoferes = async () => {
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      let choferes = await makeRequest(
        `drivers/?limit=99999`,
        { method: 'GET' },
      );
      this.setState({ choferes: choferes.rows }, () => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  getVehicle = async () => {
    const { setLoading, pushError, match: { params: { id } } } = this.props;
    try {
      setLoading(true);
      const vehicle = await makeRequest(
        `vehicles/${id}`,
        { method: 'GET' },
      );

      /* const id_user = this.state.concesionarios.find(x => x.id_user === vehicle.id_user);
      const id_driver = this.state.choferes.find(x => x.id_driver === vehicle.id_driver);
      vehicle.id_user = id_user;
      vehicle.id_driver = id_driver; */
      vehicle.id_user = vehicle.user;
      vehicle.id_driver = vehicle.driver;

      this.setState({ vehicle });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  handleFormSubmit = async (form) => {
    const { setLoading, pushAlerta, pushError, pushSuccess, match: { params: { id } } } = this.props;

    if (!form.valid) {
      pushAlerta({
        type: 'warning',
        title: '¡Hay un problema!',
        message: 'Algunos de los campos en la forma tienen problemas.',
        timer: 3000,
      });
      return;
    }

    setLoading(true);
    const body = {
      key_vehicle: form.key_vehicle.value,
      imei: form.imei.value,
      phone_number: form.phone_number.value,
      plate_number: form.plate_number.value,
      description: form.description.value,
      notes: form.notes.value,
      id_user: form.id_user.value.id_user,
      id_driver: form.id_driver.value.id_driver || null,
    };

    try {
      await makeRequest(
        `vehicles${id ? `/${id}` : ''}`,
        { method: id ? 'PUT' : 'POST' },
        body
      );
      setLoading(false);
      pushSuccess('Se han guardado los cambios.', 3000);
      this.setState({ redirect: true });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  render() {
    const { vehicle, redirect, concesionarios, choferes } = this.state;

    if (redirect) {
      return <Redirect to="/panel/vehiculos" />;
    }

    return (
      <div className="forma-vehicle">
        <WafoForm
          key={vehicle.id_vehicle}
          buttonText="Guardar cambios"
          onSubmit={this.handleFormSubmit}
          values={vehicle}
        >

          <WafoFormInput
            type="text"
            name="key_vehicle"
            placeholder="Clave de vehículo"
            label="Clave de vehículo"
            customClass="col-12 col-md-4"
            validations={{ required: true, 'text-spanish': true, maxLength: 255 }}
          />

          <WafoFormInput
            type="text"
            name="imei"
            placeholder="IMEI"
            label="IMEI de dispositivo"
            customClass="col-12 col-md-4"
            validations={{ required: true, number: true, maxLength: 15, minLength: 15 }}
            extraProps={{
              maxLength: 15,
              minLength: 15,
            }}
          />

          <WafoFormInput
            type="text"
            name="phone_number"
            placeholder="Teléfono de dispositivo"
            label="Teléfono de dispositivo"
            customClass="col-12 col-md-4"
            validations={{ required: true, phone: true }}
          />

          <WafoFormInput
            type="text"
            name="plate_number"
            placeholder="Placas de vehículo"
            label="Placas"
            customClass="col-12 col-md-4"
            validations={{ 'text-spanish': true, }}
          />

          <WafoFormInput
            type="text"
            name="description"
            placeholder="Descripción de vehículo"
            label="Descripción de vehículo"
            customClass="col-12 col-md-4"
            validations={{ 'text-spanish': true, maxLength: 255 }}
          />

          <WafoFormInput
            type="text"
            name="notes"
            placeholder="Notas adicionales"
            label="Notas adicionales"
            customClass="col-12 col-md-4"
            validations={{ 'text-spanish': true, maxLength: 255 }}
          />

          <WafoFormAutocomplete
            ref={this.autocompleteConcesionario}
            name="id_user"
            customClass="col-12 col-md-6"
            label="Concesionario"
            placeholder="Seleccione un concesionario"
            validations={{ required: true }}
            items={concesionarios}
            filterItemsFN={(items, query) => items.filter(item => (
              item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || item.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || `${item.name} ${item.last_name}`.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || item.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || (item.description && item.description.toLowerCase().indexOf(query.toLowerCase()) !== -1)
            ))}
            customItemFN={item => (
              <p style={{ margin: '0' }}>
                <span>{item.name} {item.last_name}</span>
                <br />
                <span style={{ fontSize: '0.8em', color: '#777' }}>#{item.id_user} - {item.description}</span>
              </p>
            )}
            customInputFN={item => `${item.name} ${item.last_name}`}
          />

          <WafoFormAutocomplete
            ref={this.autocompleteChofer}
            name="id_driver"
            customClass="col-12 col-md-6"
            label="Chofer"
            placeholder="Seleccione un chofer"
            items={choferes}
            filterItemsFN={(items, query) => items.filter(item => (
              item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || item.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || `${item.name} ${item.last_name}`.toLowerCase().indexOf(query.toLowerCase()) !== -1
            ))}
            customItemFN={item => `${item.name} ${item.last_name}`}
            customInputFN={item => `${item.name} ${item.last_name}`}
          />

        </WafoForm>
      </div>
    );
  }
}

export default meConnect(FormaVehicle);
