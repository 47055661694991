import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

const Navbar = ({ onToggleClick = f => f }) => (
  <nav className="navbar-panel navbar navbar-expand-lg navbar-light bg-light">
    <button
      type="button"
      className="btn btn-outline-dark btn-toggle"
      onClick={onToggleClick}
    >
      <FontAwesomeIcon icon={faBars} />
    </button>
    <a className="navbar-brand" href="/">
      Celso GPS
    </a>
  </nav>
);

export default Navbar;
