import React from 'react';
import { WafoForm, WafoFormInput } from 'wafo-forms';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import makeRequest from '../../lib/apiService';
import AlertasList from '../../components/alerta/alerta'; // pop-up msgs
import defaultConnect from '../../components/redux/defaultConnect';
import './styles.css';

class ForgotPassword extends React.Component {
  state = {
    formKey: Math.random(),
  };

  handleFormSubmit = async (form) => {
    const { pushError, pushSuccess } = this.props;

    if (!form.valid) { return; }
    try {
      await makeRequest(
        'auth/forgot',
        { method: 'POST' },
        {
          email: form.email.value,
        }
      );
      pushSuccess('Se han enviado las instrucciones a su correo.');
      this.setState({ formKey: Math.random() });
    } catch (error) {
      pushError(error.message);
    }
  }

  render() {
    const { formKey } = this.state;

    return (
      <div className="login">
        <div className="container-fluid">
          <AlertasList />
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-sm-12 col-md-3">

              <div className="card mirai-shadow">
                <div className="card-body">

                  <h1>Celso GPS</h1>

                  <div className="alert alert-info" role="alert">
                    Introduce tu correo electrónico y te enviaremos un correo con pasos para recuperar tu contraseña.
                  </div>

                  <WafoForm key={formKey} buttonText="Recuperar contraseña" onSubmit={this.handleFormSubmit}>

                    <WafoFormInput
                      type="email"
                      name="email"
                      label="Correo electrónico"
                      placeholder="Correo electrónico"
                      customClass="col-12"
                      validations={{
                        required: true,
                        email: true,
                      }}
                    />

                  </WafoForm>

                  <Link to="/login" className="btn btn-link">
                    <FontAwesomeIcon icon={faKey} style={{ marginRight: '.25rem' }} />
                    Regresar a inicio de sesión
                  </Link>

                  <p className="motd">
                    <FontAwesomeIcon icon={faCopyright} style={{ marginRight: '.25rem' }} />
                    Celso GPS 2019
                  </p>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default defaultConnect(ForgotPassword);
