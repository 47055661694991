import React from 'react';
import { WafoFormAutocomplete } from 'wafo-forms';

const items = [
  {
    display: 'Encendido',
    filter: 'power',
    value: true,
  },
  {
    display: 'Apagado',
    filter: 'power',
    value: false,
  },
  {
    display: 'Con GPS',
    filter: 'gps',
    value: true,
  },
  {
    display: 'Sin GPS',
    filter: 'gps',
    value: false,
  },
  {
    display: 'Conectado',
    filter: 'socket',
    value: true,
  },
  {
    display: 'Desconectado',
    filter: 'socket',
    value: false,
  },
];

const StatusFilter = React.forwardRef(function StatusFilter({ customClass, handleInputChange }, ref) {
  return (
    <WafoFormAutocomplete
      ref={ref}
      name="status"
      customClass={customClass}
      placeholder="Filtro por estado"
      items={items}
      filterItemsFN={(items, query) => items.filter(item => item.display.toLowerCase().indexOf(query.toLowerCase()) !== -1)}
      customInputFN={item => item.display}
      customItemFN={item => item.display}
      handleInputChange={handleInputChange}
      itemsLimit={10}
    />
  );
});

export default StatusFilter;