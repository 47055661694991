import React from 'react';
import { Datatable } from 'wafo-table';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import defaultConnect from '../../../components/redux/defaultConnect';
import makeRequest from '../../../lib/apiService';
import Confirmacion from '../../../components/confirmacion/confirmacion';

class TablaChoferes extends React.Component {
  state = {
    tabla: {
      rows: [],
      total: 0,
      activePage: 1,
      size: 10,
    },
    confirmation: {
      show: false,
      message: '',
      accept: f => f,
      acceptParams: {},
    },
  };

  componentDidMount() {
    this.getData();
  }

  getData = async (page = 1) => {
    const { tabla: { size } } = this.state;
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      const resp = await makeRequest(
        `drivers/?limit=${size}&page=${page}`,
        { method: 'GET' },
      );
      this.setState((prevState) => ({
        tabla: {
          ...prevState.tabla,
          rows: resp.rows,
          total: resp.count,
          activePage: page,
        }
      }), () => { setLoading(false); });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  paginationHandler = (event, value) => {
    const { tabla, tabla: { activePage } } = this.state;

    switch (event) {
      case 'page': {
        let pagina = value;
        if (pagina === 'prev') {
          pagina = (activePage - 1);
        } else if (pagina === 'next') {
          pagina = (activePage + 1);
        }
        this.getData(pagina);
        break;
      }
      case 'size':
        this.setState({ tabla: { ...tabla, size: parseInt(value, 10) } }, () => {
          this.getData(activePage);
        });
        break;
      default: break;
    }
  }

  toggleConfirmation = (confirmation) => this.setState((prevState) => ({
    confirmation: {
      ...prevState.confirmation,
      ...confirmation,
      show: !prevState.confirmation.show,
    },
  }));

  deleteConfirmation = (id) => this.toggleConfirmation({
    message: 'El chofer será eliminado completamente del sistema.',
    accept: this.deleteChofer,
    acceptParams: { id },
  });

  deleteChofer = async () => {
    const { setLoading, pushError, pushSuccess } = this.props;
    const {
      tabla: { activePage },
      confirmation: { acceptParams: { id } }
    } = this.state;

    try {
      this.toggleConfirmation();
      setLoading(true);
      await makeRequest(
        `drivers/${id}`,
        { method: 'DELETE' },
      );
      setLoading(false);
      pushSuccess('Se ha realizado el cambio con exito.', 3000);
      this.getData(activePage);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  render() {
    const { tabla, confirmation } = this.state;
    const columns = ['ID', 'Nombre', 'Correo electrónico', 'Teléfono', 'Concesionario', 'Opciones'];

    const rows = tabla.rows.map(row => ({
      id: row.id_driver,
      name: `${row.name} ${row.last_name}`,
      email: row.email,
      phone: row.phone_number,
      user: row.id_user,
      options: row.id_driver
    }));

    const configTable = {
      columnDef: {
        id: val => (<span>#{val}</span>),
        user: val => (
          <Link className="badge badge-primary" to={`/panel/concesionarios/concesionario/detalles/${val}`}>
            Concesionario #{val}
          </Link>
        ),
        options: val => (
          <React.Fragment>
            <Link className="btn btn-sm btn-light" to={`choferes/chofer/detalles/${val}`}>
              <FontAwesomeIcon icon={faList} style={{ marginRight: '.25rem' }} />
              Ver más
            </Link>
            <Link className="btn btn-sm btn-sl-2" to={`choferes/chofer/${val}`}>
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <button type="button" className="btn btn-sm btn-danger" onClick={() => { this.deleteConfirmation(val); }}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </React.Fragment>
        ),
      },
      columnStyle: {
        options: {
          whiteSpace: 'nowrap',
        },
      },
    };

    return (
      <div className="tabla-choferes">

        <Datatable
          tableProps={{
            columns,
            data: rows,
            configTable
          }}
          total={tabla.total}
          activePage={tabla.activePage}
          size={tabla.size}
          paginacionEvent={this.paginationHandler}
          updateTable={() => { this.getData(tabla.activePage); }}
        />

        {confirmation.show && <Confirmacion message={confirmation.message} accept={confirmation.accept} cancel={() => { this.toggleConfirmation(undefined); }} />}

      </div>
    );
  }
}

export default defaultConnect(TablaChoferes);
