import React from 'react';
import { WafoForm, WafoFormInput } from 'wafo-forms';
import { Redirect } from 'react-router-dom';
import defaultConnect from '../../../components/redux/defaultConnect';
import makeRequest from '../../../lib/apiService';

class FormaConcesionario extends React.Component {
  state = {
    user: {
      id_user: 0,
    },
    redirect: false,
  };

  componentDidMount() {
    const { match: { params: { id } } } = this.props;
    if (id) {
      this.getConcesionario();
    }
  }

  getConcesionario = async () => {
    const { setLoading, pushError, match: { params: { id } } } = this.props;
    try {
      setLoading(true);
      const user = await makeRequest(
        `users/${id}`,
        { method: 'GET' },
      );
      this.setState({ user });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  handleFormSubmit = async (form) => {
    const { setLoading, pushAlerta, pushError, pushSuccess, match: { params: { id } } } = this.props;

    if (!form.valid) {
      pushAlerta({
        type: 'warning',
        title: '¡Hay un problema!',
        message: 'Algunos de los campos en la forma tienen problemas.',
        timer: 3000,
      });
      return;
    }

    setLoading(true);
    const body = {
      email: form.email.value,
      password: form.password.value,
      name: form.name.value,
      last_name: form.last_name.value,
      phone_number: form.phone_number.value,
      description: form.description.value,
    };
    if (!body.password) { delete body.password; }

    try {
      await makeRequest(
        `users${id ? `/${id}` : ''}`,
        { method: id ? 'PUT' : 'POST' },
        body
      );
      setLoading(false);
      pushSuccess('Se han guardado los cambios.', 3000);
      this.setState({ redirect: true });
    } catch (error) {
      pushError(error.message);
      setLoading(false);
    }
  }

  render() {
    const { user, redirect } = this.state;
    const { match: { params: { id } } } = this.props;

    if (redirect) {
      return <Redirect to="/panel/concesionarios" />;
    }

    return (
      <div className="forma-concesionario">

        {id &&
          (
            <div className="alert alert-secondary" role="alert">
              <strong>Nota:</strong> Dejar la contraseña en blanco si no se desea cambiar.
            </div>
          )
        }

        {/* formas */}
        <WafoForm
          key={user.id_user}
          buttonText="Guardar cambios"
          onSubmit={this.handleFormSubmit}
          values={user}
        >

          <WafoFormInput
            type="text"
            name="name"
            placeholder="Nombre"
            label="Nombre(s)"
            customClass="col-12 col-md-4"
            validations={{ required: true, 'text-spanish': true, maxLength: 255 }}
          />

          <WafoFormInput
            type="text"
            name="last_name"
            placeholder="Apellidos"
            label="Apellidos"
            customClass="col-12 col-md-4"
            validations={{ required: true, 'text-spanish': true, maxLength: 255 }}
          />

          <WafoFormInput
            type="email"
            name="email"
            placeholder="Correo electrónico"
            label="Correo electrónico"
            customClass="col-12 col-md-4"
            validations={{ required: true, email: true, maxLength: 255 }}
          />

          <WafoFormInput
            type="text"
            name="phone_number"
            placeholder="Número de télefono"
            label="Número de télefono"
            customClass="col-12 col-md-4"
            validations={{ phone: true }}
          />

          <WafoFormInput
            type="password"
            name="password"
            placeholder="Contraseña"
            label="Contraseña"
            customClass="col-12 col-md-4"
            validations={{ required: id ? false : true, maxLength: 100 }}
          />

          <WafoFormInput
            type="password"
            name="password2"
            placeholder="Confirmar contraseña"
            label="Confirmar contraseña"
            customClass="col-12 col-md-4"
            validations={{ required: id ? false : true, maxLength: 100 }}
          />

          <WafoFormInput
            type="text"
            name="description"
            placeholder="Datos adicionales..."
            label="Datos adicionales"
            customClass="col-12 col-md-12"
            validations={{ maxLength: 255 }}
          />

        </WafoForm>

      </div>
    );
  }
}

export default defaultConnect(FormaConcesionario);