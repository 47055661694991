import React from 'react';
import { Datatable } from 'wafo-table';
import { WafoFormAutocomplete } from 'wafo-forms';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCheckDouble, faMapMarkedAlt, faEraser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { updateLoadingBar, pushAlertaSuccess, pushAlertaError, pushAlerta } from '../../../store/reducers';
import makeRequest from '../../../lib/apiService';
import Confirmacion from '../../../components/confirmacion/confirmacion';

const types = [
  {
    display: 'Desconexión',
    value: 'disconnected',
  },
  {
    display: 'Señal GPS',
    value: 'L',
  },
  {
    display: 'Boton de emergencia',
    value: 'help me',
  },
  {
    display: 'Batería baja',
    value: 'low battery',
  },
  {
    display: 'Exceso de velocidad',
    value: 'speed',
  },
  {
    display: 'Fuera de zona',
    value: 'stockade',
  },
];

const status = [
  {
    display: 'Pendientes',
    value: 'false',
  },
  {
    display: 'Vistas',
    value: 'true',
  },
];

const defaultFilters = {
  vehicle: '',
  type: '',
  status: '',
};

class TablaAlertas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        rows: [],
        total: 0,
        activePage: 1,
        size: 10,
      },
      confirmation: {
        show: false,
        message: '',
        accept: f => f,
        acceptParams: {},
      },
      filters: defaultFilters,
      vehiclesFilter: [],
    };

    this.typeAutocomplete = React.createRef();
    this.statusAutocomplete = React.createRef();
    this.vehiclesAutocomplete = React.createRef();
  }

  componentDidMount() {
    this.getData();
    this.getVehiclesFilter();
  }

  getVehiclesFilter = async () => {
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      const vehicles = await makeRequest(
        `vehicles/?limit=${999999}`,
        { method: 'GET' },
      );
      this.setState({
        vehiclesFilter: vehicles.rows,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  };

  getData = async (page = 1) => {
    const { table: { size }, filters } = this.state;
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      const resp = await makeRequest(
        `notifications/?limit=${size}&page=${page}`
        + `${filters.type ? `&type=${filters.type.value}` : ''}`
        + `${filters.status ? `&seen=${filters.status.value}` : ''}`
        + `${filters.vehicle ? `&id_vehicle=${filters.vehicle.id_vehicle}` : ''}`,
        { method: 'GET' },
      );
      this.setState((prevState) => ({
        table: {
          ...prevState.table,
          rows: resp.rows,
          total: resp.count,
          activePage: page,
        }
      }), () => { setLoading(false); });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  paginationHandler = (event, value) => {
    const { table, table: { activePage } } = this.state;

    switch (event) {
      case 'page': {
        let pagina = value;
        if (pagina === 'prev') {
          pagina = (activePage - 1);
        } else if (pagina === 'next') {
          pagina = (activePage + 1);
        }
        this.getData(pagina);
        break;
      }
      case 'size':
        this.setState({ table: { ...table, size: parseInt(value, 10) } }, () => {
          this.getData(activePage);
        });
        break;
      default: break;
    }
  }

  toggleConfirmation = (confirmation) => this.setState((prevState) => ({
    confirmation: {
      ...prevState.confirmation,
      ...confirmation,
      show: !prevState.confirmation.show,
    },
  }));

  markAsSeenConfirmation = (id) => this.toggleConfirmation({
    message: 'La notificación será marcada como vista en todos los dispositivos asociados a esta cuenta.',
    accept: this.markAsSeen,
    acceptParams: { id },
  });

  markAsSeen = async () => {
    const { setLoading, pushError, pushSuccess } = this.props;
    const {
      table: { activePage },
      confirmation: { acceptParams: { id } }
    } = this.state;

    try {
      this.toggleConfirmation();
      setLoading(true);
      await makeRequest(
        `notifications/read/${id}`,
        { method: 'GET' },
      );
      setLoading(false);
      pushSuccess('Se ha realizado el cambio con exito.', 3000);
      this.getData(activePage);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  markAllAsSeenConfirmation = () => this.toggleConfirmation({
    message: 'Todas las notificaciones serán marcadas como vistas en todos los dispositivos asociados a esta cuenta.',
    accept: this.markAllAsSeen,
    acceptParams: {},
  });

  markAllAsSeen = async () => {
    const { setLoading, pushError, pushSuccess } = this.props;
    const { table: { activePage } } = this.state;

    try {
      this.toggleConfirmation();
      setLoading(true);
      await makeRequest(
        'notifications/read/all',
        { method: 'GET' },
      );
      setLoading(false);
      pushSuccess('Se ha realizado el cambio con exito.', 3000);
      this.getData(activePage);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  handleFilterChange = (event) => {
    const { target: { name, value } } = event;
    this.setState((prevState) => ({
      table: {
        ...prevState.table,
        activePage: 1
      },
      filters: {
        ...prevState.filters,
        [name]: value,
      }
    }), this.getData);
  }

  clearFilters = () => this.setState({ filters: defaultFilters }, () => {
    this.statusAutocomplete.current.clearForm();
    this.typeAutocomplete.current.clearForm();
    this.vehiclesAutocomplete.current.clearForm();
    this.getData();
  });

  render() {
    const { me } = this.props;
    const { table, confirmation, vehiclesFilter } = this.state;
    const columns = ['ID', 'Estado', 'Alerta', 'Vehículo', 'Fecha', 'Opciones'];

    const rows = table.rows.map(row => ({
      id: row.id_notification,
      status: me.type === 1 ? row.seen_admin : row.seen_user,
      type: row.type,
      vehicle: { key: row.key_vehicle, id: row.id_vehicle },
      date: row.createdAt,
      options: {
        id: row.id_notification,
        seen: me.type === 1 ? row.seen_admin : row.seen_user,
        latitude: row.latitude,
        longitude: row.longitude
      },
    }));

    const configTable = {
      columnDef: {
        id: val => (<span>#{val}</span>),
        status: val => {
          switch (val) {
            case true:
              return <span className="badge badge-status badge-light"><FontAwesomeIcon icon={faCheckDouble} /> Vista</span>;
            case false:
              return <span className="badge badge-status badge-warning"><FontAwesomeIcon icon={faEye} /> Pendiente</span>;
            default:
              return <span className="badge badge-dark">N/A</span>;
          }
        },
        type: val => {
          switch (val) {
            case 'disconnected': return <span className="badge badge-light">Desconexión</span>;
            case 'L': return <span className="badge badge-light">Señal GPS</span>;
            case 'help me': return <span className="badge badge-light">Boton de emergencia</span>;
            case 'low battery': return <span className="badge badge-light">Batería baja</span>;
            case 'speed': return <span className="badge badge-light">Exceso de velocidad</span>;
            case 'stockade': return <span className="badge badge-light">Fuera de zona</span>;
            default: return <span className="badge badge-light">{val}</span>;
          }
        },
        vehicle: val => (
          <Link className="badge badge-primary" to={`/panel/vehiculos/vehiculo/detalles/${val.id}`}>
            {val.key}
          </Link>
        ),
        date: val => (<span>{moment(val).format('DD-MMM-YYYY HH:mm:ss')}</span>),
        options: val => (
          <React.Fragment>
            <a
              className="btn btn-sm btn-light"
              title="Ir a Google Maps"
              href={`https://www.google.com/maps/search/?api=1&query=${val.latitude},${val.longitude}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faMapMarkedAlt} />
            </a>
            <button disabled={val.seen} type="button" className="btn btn-sm btn-warning" title="Marcar como vista" onClick={() => { this.markAsSeenConfirmation(val.id); }}>
              <FontAwesomeIcon icon={faCheckDouble} style={{ marginRight: '.25rem' }} />
              Marcar vista
            </button>
          </React.Fragment>
        )
      },
      columnStyle: {
        options: {
          whiteSpace: 'nowrap',
        },
      },
    };

    return (
      <div className="tabla-alertas">

        <div className="row filtros">

          <WafoFormAutocomplete
            ref={this.vehiclesAutocomplete}
            name="vehicle"
            customClass="col-12 col-md-3"
            placeholder="Busqueda por vehículo"
            items={vehiclesFilter}
            filterItemsFN={(items, query) => items.filter(item => (
              item.imei.indexOf(query) !== -1
              || item.key_vehicle.toLowerCase().indexOf(query.toLowerCase()) !== -1
            ))}
            customInputFN={item => `${item.key_vehicle}`}
            customItemFN={item => (
              <p style={{ margin: '0' }}>
                <span>{item.key_vehicle}</span>
                <br />
                <span style={{ fontSize: '0.8em', color: '#777' }}>{item.description}</span>
              </p>
            )}
            handleInputChange={this.handleFilterChange}
            extraProps={{
              autoComplete: 'off',
            }}
          />

          <WafoFormAutocomplete
            ref={this.typeAutocomplete}
            name="type"
            customClass="col-12 col-md-3"
            placeholder="Tipo de alerta"
            items={types}
            filterItemsFN={items => items}
            customInputFN={item => item.display}
            customItemFN={item => item.display}
            handleInputChange={this.handleFilterChange}
            itemsLimit={10}
            extraProps={{
              autoComplete: 'off',
            }}
          />

          <WafoFormAutocomplete
            ref={this.statusAutocomplete}
            name="status"
            customClass="col-12 col-md-2"
            placeholder="Estado de alerta"
            items={status}
            filterItemsFN={items => items}
            customInputFN={item => item.display}
            customItemFN={item => item.display}
            handleInputChange={this.handleFilterChange}
            extraProps={{
              autoComplete: 'off',
            }}
          />

          <div className="botones col-12 col-md-4">
            <button type="button" className="btn btn-warning" style={{ marginRight: '1rem' }} onClick={this.markAllAsSeenConfirmation}>
              <FontAwesomeIcon icon={faCheckDouble} style={{ marginRight: '.25rem' }} />
              Marcar todos
            </button>
            <button type="button" className="btn btn-light" onClick={this.clearFilters}>
              <FontAwesomeIcon icon={faEraser} style={{ marginRight: '.25rem' }} />
              Limpiar filtros
            </button>
          </div>

        </div>

        <Datatable
          tableProps={{
            columns,
            data: rows,
            configTable,
          }}
          total={table.total}
          activePage={table.activePage}
          size={table.size}
          paginacionEvent={this.paginationHandler}
          updateTable={() => { this.getData(table.activePage); }}
        />

        {confirmation.show && <Confirmacion message={confirmation.message} accept={confirmation.accept} cancel={() => { this.toggleConfirmation(undefined); }} />}

      </div>
    );
  }
}

export default connect(
  state => ({
    me: state.me,
  }),
  dispatch => ({
    pushAlerta: (alerta) => dispatch(pushAlerta(alerta)),
    pushSuccess: (msg, timeout = 0) => dispatch(pushAlertaSuccess(msg, timeout)),
    pushError: (error, timeout = 0) => dispatch(pushAlertaError(error, timeout)),
    setLoading: (loadingBar) => dispatch(updateLoadingBar(loadingBar))
  })
)(TablaAlertas);
