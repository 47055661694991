import React from 'react';
import { WafoForm, WafoFormInput } from 'wafo-forms';
import { Redirect, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import makeRequest from '../../lib/apiService';
import AlertasList from '../../components/alerta/alerta'; // pop-up msgs
import defaultConnect from '../../components/redux/defaultConnect';
import './styles.css';

class ChangePassword extends React.Component {
  state = {
    toPanel: false,
  };

  componentDidMount() {
    const { match: { params: { token } } } = this.props;
    if (token) {
      this.checkToken();
    }
  }

  checkToken = async () => {
    const { pushError, pushSuccess, match: { params: { token } } } = this.props;
    try {
      await makeRequest(
        `auth/forgot?token=${token}`,
        { method: 'GET' },
      );
    } catch (error) {
      pushError(error.message);
    }
  }

  handleFormSubmit = async (form) => {
    const { pushError, pushSuccess, match: { params: { token } } } = this.props;

    if (!form.valid) { return; }
    try {
      await makeRequest(
        'auth/forgot/newpassword',
        { method: 'POST' },
        {
          password: form.password.value,
          token,
        }
      );
      pushSuccess('Se ha cambiado con exito su contraseña. Inicie sesión.');
      this.setState({ toPanel: true });
    } catch (error) {
      pushError(error.message);
    }
  }

  render() {
    const { toPanel } = this.state;

    if (toPanel) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="login">
        <div className="container-fluid">
          <AlertasList />
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-sm-12 col-md-3">

              <div className="card mirai-shadow">
                <div className="card-body">

                  <h1>Celso GPS</h1>

                  <div className="alert alert-info" role="alert">
                    Estas a punto de cambiar tu contraseña; con ella podrás iniciar sesión a partir de ahora.
                  </div>

                  <WafoForm buttonText="Cambiar contraseña" onSubmit={this.handleFormSubmit}>

                    <WafoFormInput
                      type="password"
                      name="password"
                      label="Nueva contraseña"
                      placeholder="Nueva contraseña"
                      customClass="col-12"
                      validations={{
                        required: true,
                      }}
                    />

                  </WafoForm>

                  <Link to="/login" className="btn btn-link">
                    <FontAwesomeIcon icon={faKey} style={{ marginRight: '.25rem' }} />
                    Regresar a inicio de sesión
                  </Link>

                  <p className="motd">
                    <FontAwesomeIcon icon={faCopyright} style={{ marginRight: '.25rem' }} />
                    Celso GPS 2019
                  </p>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default defaultConnect(ChangePassword);
